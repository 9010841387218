var nav = document.querySelector('.nav');
var navToggle = document.querySelector('.header__nav-toggle');

var toggleNav = function() {
  nav.classList.toggle('nav--active');
};

if (navToggle) {
  navToggle.addEventListener('click', toggleNav);
}
