// flickity slideshow

var Flickity = require('flickity');
require('flickity-imagesloaded');

var gallery = document.querySelectorAll('.gallery');

var previous = function() {
  flkty.previous();
};

var next = function() {
  flkty.next();
};

for (var i = 0; i < gallery.length; i += 1) {

  var slideshow = gallery[i].querySelector('.gallery__slideshow');
  var buttonPrevious = gallery[i].querySelector('.gallery__previous');
  var buttonNext = gallery[i].querySelector('.gallery__next');

  var flkty = new Flickity(slideshow, {
    cellSelector: '.gallery__item',
    cellAlign: 'center',
    imagesLoaded: true,
    percentPosition: false,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false
  });

  buttonPrevious.addEventListener('click', previous);

  buttonNext.addEventListener('click', next);

}

// minimodal lightbox

var minimodal = require('minimodal');

var target = document.querySelectorAll('.gallery__item');

for (var i = 0; i < target.length; i += 1) {
  var modal = minimodal(target[i], {
    // options
  });
  modal.init();
}
